import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import { createPinia, setActivePinia } from 'pinia'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css'

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

const pinia = createPinia()
setActivePinia(pinia)

createApp(App).use(router).use(pinia).mount('#app')
