<template>
<div>
  <!-- Todo change component name-->
  <button type="button" id="modalToggleButton" class="btn" @click="modal.show(); addEventListener()">
    Create Return Order
  </button>

  <div class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="true" 
  tabindex="-1" 
  aria-labelledby="staticBackdropLabel" 
  aria-hidden="true"
  ref="modal"
  >
    <div class="modal-dialog modal-xl" id="modalDialog">
      <div class="modal-content" id="modalContent">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Create Return Order</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="removeEventListener()"></button>
        </div>
        <div class="modal-body">
          <form>           
            <div class="input-group mb-3">
              <label class="input-group-text" for="ean">EAN</label>
              <input type="text" id="ean" autofocus v-model="ean" class="form-control" ref="ean" @keydown.enter="$refs.rma.focus()">
            </div>
            <div class="input-group mb-3">
              <label class="input-group-text"  for="rma">RMA</label>
              <input type="text" id="rma" ref="rma" v-model="rma" @keydown.enter="$refs.trackingId.focus()" class="form-control">
            </div>
            <div class="input-group mb-3">
              <label class="input-group-text" for="trackingId">Tracking-Id</label>
              <input type="text" id="trackingId" ref="trackingId" @keydown.enter="$refs.customerName.focus()" v-model="trackingId" class="form-control">
            </div>
            <div class="input-group mb-3">
              <label class="input-group-text" for="customerName">Customer Name</label>
              <input type="text" id="customerName" ref="customerName" v-model="customerName" class="form-control">
            </div>
            <div class="input-group mb-3">
              <label class="input-group-text" for="quantity">Quantity</label>
              <input type="number" id="quantity" v-model="quantity" class="form-control">
            </div>
            <div class="input-group mb-3">
              <label class="input-group-text" for="customerName">Article Condition</label>
              <select v-model="articleCondition" id="customerName" class="form-control">
                <option selected>Choose an option</option>
                <option value="A - New">A - New</option>
                <option value="B - Box Damaged / Taped not in new condition / Seal broken / Not sealed">
                  B - Box Damaged / Taped not in new condition / Seal broken / Not sealed
                </option>
                <option value="C - Item looks worn / used /Item is damaged / Missing item / Box Empty">
                  C - Item looks worn / used /Item is damaged / Missing item / Box Empty
                </option>
              </select>
            </div>
            <div class="input-group mb-3">
              <label class="input-group-text" for="comment">Comment</label>
              <input type="text" id="comment" v-model="comment" class="form-control">
            </div>
            <div class="input-group mb-3">
              <label class="input-group-text" for="packageType">Package Type</label>
              <select v-model="packageType" id="packageType" class="form-control">
                <option selected>Choose an option</option>
                <option value="big">big</option>
                <option value="small">small</option>
                <option value="trash">trash</option>
              </select>
            </div>
            <webcam-component ref="webcamCmp" v-model="attachments"/>
          </form>
          <div v-if="errorMessage !== ''" class="text-danger w-50 text-center mx-auto mt-3 border border-danger">
            <h4>Error</h4>
            <b>{{errorMessage}}</b>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary mr-1" data-bs-dismiss="modal" @click="resetFields(false); removeEventListener()" id="closeModalButton">cancel</button>
          <button type="button" class="btn btn-primary mr-1" @click="save(true); $refs.ean.focus()">Add Item for same customer</button>
          <button type="button" class="btn btn-primary mr-1" @click="save(false); $refs.ean.focus()" >Add another Item</button>
          <button type="button" class="btn btn-primary mr-1" @click="saveAndClose()"> Save and Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from '../interceptor/axiosInterceptor'
import WebcamComponent from './WebcamComponent.vue'
import moment from 'moment'

import { useAuthStore } from "@/stores/auth.store"
import { mapStores } from 'pinia'

import { Modal } from 'bootstrap';

export default {

  name: "ReturnOrderEdit",
  components: {
    WebcamComponent
  },
  data(){
    return {
      currentHub: "",
      ean: '',
      rma: '',
      trackingId: '',
      customerName: '',
      quantity: 1,
      articleCondition: 'Choose an option',
      comment: '',
      packageType: 'Choose an option',
      attachments: [],
      errorMessage: '',
      dismissModal: 'none',
      isModalStatic: false,
      modal: null
    }
  },
  computed:{
    ...mapStores(useAuthStore)
  },
  methods:{
    isSubmittable(){
      if(this.ean === ''){
        this.errorMessage = 'Please input the ean';
        return false;
      } 
      else if(this.ean.length < 12 || this.ean.length > 13){
        this.errorMessage = 'Incorrect ean';
        return false;
      }
      else if(this.rma === ''){
        this.errorMessage = 'Please input the rma';
        return false;
      }
      else if(this.trackingId === ''){
        this.errorMessage = 'Please input the Tracking-Id';
        return false;
      }
      else if(this.customerName === ''){
        this.errorMessage = 'Please input the name of the customer';
        return false;
      }
      else if(this.articleCondition === 'Choose an option'){
        this.errorMessage = 'please choose the article condition';
        return false;
      }
      else if(this.packageType === 'Choose an option'){
        this.errorMessage = 'please choose the package type';
        return false;
      }
      else if(this.attachments.length < 2 ){
        this.errorMessage = 'please take at least 2 pictures of the article';
        return false;
      } else {
        this.errorMessage = '';
        return true;
      }
    },
    save(isSameCustomer){
      if (!this.isSubmittable()) return;
      this.convertAttachmentsTobase64(this.attachments)
      .then( results => {
        const b64Attachments = results.map(b64String => {
          return b64String.substr(b64String.indexOf(',') + 1);
        })
        axios.get('/hub/' + this.authStore.user?.hubId)
        .then(res=> {
          this.currentHub = res.data;

          const req = {
            id : this.currentHub._id + '-' + this.currentHub.returnOrderIncrement,
            ean : this.ean,
            rma : this.rma,
            trackingId : this.trackingId,
            customerName : this.customerName,
            quantity: this.quantity,
            articleCondition: this.articleCondition.slice(0,1),
            comment: this.comment,
            packageType: this.packageType,
            recievedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            isDeleted: false,
            shipmentId: null,
            hubId: this.authStore.user?.hubId,
            attachments : b64Attachments
          }
          // console.log(req);
          axios.post('/return_order/create/', req)
          .then(() => {
            this.resetFields(isSameCustomer)
            this.$refs.ean.focus()
          }).catch(err => {
            console.log(err);
            this.errorMessage = "An Error occurred while saving Return Order to Database. Error: " + err
          })
        }).catch(err => {
              console.log(err);
              this.errorMessage = "An Error occurred while fetching hub Data. Error: " + err
        })
      })
      .catch(err => {
        this.errorMessage = "An Error occurred while converting Images. Error: " + err
      })
    },
    async convertAttachmentsTobase64(blobArray){
      return await Promise.all(blobArray.map(blob => {
        return new Promise((resolve, reject) =>{
          const reader = new FileReader();
          reader.readAsDataURL(blob)
          reader.onloadend = function(){
            resolve(reader.result);
          };
          reader.onerror = function(error){
            reject(error);
          };
        })
      }))
    },
    resetFields(isSameCustomer){
      this.id = ''
      this.ean =  '';
      this.rma = isSameCustomer ? this.rma : '';
      this.trackingId = isSameCustomer ? this.trackingId : '';
      this.customerName =  isSameCustomer ? this.customerName : '';
      this.articleCondition = 'Choose an option';
      this.attachments = [];
      this.comment =  '';
      this.$refs.webcamCmp.resetImages();
    },
    async saveAndClose(){
      try {
        if (!this.isSubmittable()) return;
        await this.save(false)
        if(this.errorMessage === ""){
          this.modal.hide();
          window.removeEventListener("keydown", this.keyShortcuts);
        }

      }catch (err){
        this.errorMessage = "An Error occurred while fetching hub Data. Error: " + err;
      }
    },
    keyShortcuts(event){
      if (event.ctrlKey && event.key === 'b') {
        this.save(true)
      } else if (event.ctrlKey && event.key === 'n') {
        this.save(false)
      } else if (event.ctrlKey && event.key === 'm'){
        this.saveAndClose();
      } else {
        return;
      }
    },
    addEventListener(){
      window.addEventListener("keydown", this.keyShortcuts);
    },
    removeEventListener(){
      window.removeEventListener("keydown", this.keyShortcuts);
    }
  },
  created(){
    axios.get('/hub/' + this.authStore.user?.hubId)
    .then(res=> {
      this.currentHub = res.data;
      // console.log(res);
    })
    .catch(err => {
      console.log(err);
    })
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);
  },
}
</script>