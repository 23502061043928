<template>
<div>
  <nav v-show="authStore.token" class="main-header navbar navbar-expand justify-content-between" style="background-color: #b0b0ae">
    <div class="d-flex">
      <button class="btn mx-2">
        <RouterLink to="/" class="icon-link">
            <!-- <i class="bi bi-bootstrap-reboot" style="font-size: 1.5rem; color: white;"></i> -->
            Home
        </RouterLink>
      </button>
      <button class="btn mx-2">
         <RouterLink to="/shipments" class="text-decoration-none text-reset">
          Shipments
        </RouterLink>
      </button>
    </div>
    <div class="d-flex">
      <return-order-edit></return-order-edit>
      <button class="btn mx-2">
        <RouterLink to="/shipment/create" class="text-decoration-none text-reset">
          Create Shipment
        </RouterLink>
      </button>
    </div>
    <div>
      <button v-if="authStore?.user?.role === 'admin'" class="btn mx-2"><a href="/create_user" class="text-decoration-none text-reset">Create User</a></button>
      <button @click="authStore.logout()" class="btn mx-2">Logout</button>
    </div>
  </nav>
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
</div>
</template>

<script>
// import MainNavbar from './components/Navbar.vue'
import { useAuthStore } from './stores/auth.store'
import { mapStores } from 'pinia'
import ReturnOrderEdit from './components/ReturnOrderEdit.vue'


export default {
  components: { ReturnOrderEdit },
  name: 'App',
  computed:{
    ...mapStores(useAuthStore)
  }, 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
