<template>
    <div>
        <div v-show="!inputActive">
            <p @click="openInput">
                {{ editableValue ? editableValue : "-" }}
            </p>
        </div>
        <div v-show="inputActive">
          <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" v-model="inputText" ref="inputfield" @keyup.enter="commitInput" @keyup.esc="cancelInput">
            <button class="btn btn-outline-success" type="button" @click="commitInput">
              <i class="bi bi-check"></i>
            </button>
            <button class="btn btn-outline-danger" type="button" @click="cancelInput">
              <i class="bi bi-x"></i>
            </button>
          </div>
        </div>
<!--        <modal-error-component v-model="modalError"></modal-error-component>-->
    </div>
</template>
<script>

import axios from "@/interceptor/axiosInterceptor";

export default{
  components: {  },
    props:{
        modelValue: {
            type: [String, Number],
            required: false,
        },
        dataId: {
            type: String,
            required: false,
        },
        dataPath: {
            type: String,
            required: false,
        }
    },
    data(){
        return{
            inputText: null,
            inputActive: false,
            modalError: {
                show: false,
                message: "unpopulated",
                error: "unpopulated",
                title: "unpopulated"
            },
        }
    },
    methods:{
        cancelInput() {
            this.inputActive = false;
            this.inputText = null;
        },
        openInput() {
            this.inputActive = true;
            this.inputText = this.modelValue;
/*            Vue.nextTick( () => {
                this.$refs.inputfield.focus();
            })*/
        },
        commitInput() {
            axios.put(this.dataPath, {
                id: this.dataId,
                value: this.inputText,
            })
            .then(() => {
                this.editableValue = this.inputText
                this.inputActive = false;
                location.reload();
            }).
            catch(error => {
                console.log("c-t-e component: commitInput.catch")
                console.log(error)
                this.modalError = {
                    show: true,
                    message: "Speichern fehlgeschalgen.",
                    error: error,
                    title: "Fehlerr beim Speichern"
                };
            })
        }
    },
    computed: {
        editableValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>
<style>
</style>