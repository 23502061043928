import axios from 'axios';
import { useAuthStore } from '../stores/auth.store'
import router from '../router/index'

axios.interceptors.request.use(
  (config) => {
    // console.log('interceptor');
    const authStore = useAuthStore();
    const token = authStore.token;
    // console.log(token);

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      // console.log('interceptor - Token is there');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 
    if (error.response && error.response.status === 401) {
      router.push('/login')
      // console.log('intercepter router push');
    }
    return Promise.reject(error);
  }
);

export default axios;