<template>
  <div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Hub-Id</th>
        <th scope="col">Order Count</th>
        <th scope="col">createdAt</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(shipment) in shipments" :key="shipment._id">
        <td>{{shipment.doc._id}}</td>
        <td>{{shipment.doc.hubId}}</td>
        <td>{{shipment.doc.orderCount}}</td>
        <td>{{shipment.doc.createdAt}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import  axios from '../interceptor/axiosInterceptor'
export default {
  data(){
    return {
      shipments :[],
      activeCollapse: null
    }
  },
  methods: {
  },
  created(){
    axios.get('/shipment/all')
      .then(res =>{
        // console.log(res)
        this.shipments = res.data.rows
      } )
      .catch(err => console.log(err))
  }
}
</script>