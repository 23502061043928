import { defineStore } from 'pinia';
import router from '../router/index'
import axios from '../interceptor/axiosInterceptor';

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({    
        user: JSON.parse(localStorage.getItem('user')),
        token: JSON.parse(localStorage.getItem('token')),
        returnUrl : null,
    }),
    actions: {
        async login(email, password) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/login', {email, password})
                .then(res => {
                    // console.log('login store response');
                    // console.log(res);
                    this.token = res.data.access_token
                    this.user = res.data.user
                    localStorage.setItem('token', JSON.stringify(this.token));
                    localStorage.setItem('user', JSON.stringify(this.user));
                    if(this.returnUrl === '/login'){
                        this.returnUrl = null
                    }
                    resolve(router.push(this.returnUrl || '/'))
                }).catch(err => {
                    reject(err)
                    
                })  
            })          
        },
        logout() {
            this.token = null;
            localStorage.removeItem('token');
            router.push('/login');
        },
        async getTokenFromPinia(){
            return this.token
        }
    }
 
});

