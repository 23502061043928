<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Return Order Id</th>
          <th scope="col">EAN</th>
          <th scope="col">RMA</th>
          <th scope="col">Tracking-Id</th>
          <th scope="col">Customer Name</th>
          <th scope="col">Quantity</th>
          <th scope="col">Condition</th>
          <th scope="col">Comment</th>
          <th scope="col">Package Type</th>
          <th scope="col">Received</th>
          <th scope="col">Attachments</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in returnOrders" :key="order.id">
          <td>{{order.doc.id}}</td>
          <td>{{order.doc.ean}}</td>
          <td>{{order.doc.rma}}</td>
          <td>{{order.doc.trackingId}}</td>
          <td>{{order.doc.customerName}}</td>
          <td>{{order.doc.quantity}}</td>
          <td>{{order.doc.articleCondition}}</td>
          <td>{{order.doc.comment}}</td>
          <td>{{order.doc?.packageType}}</td>
          <td>{{order.doc.recievedAt}}</td>
          <td>{{order.doc._attachments ? Object.keys(order.doc._attachments).length : '-'}}</td>
          <td><button class="btn btn-danger" type="button" @click="deleteOrder(order)"><i class="bi bi-trash3"></i></button></td>
            <!-- <button class="btn btn-secondary" type="button"><i class="bi bi-pencil-square"></i> WIP </button> --> 
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '../interceptor/axiosInterceptor'
import { useAuthStore } from "@/stores/auth.store"
// import { mapStores } from 'pinia'

export default {
  setup(){
    const authStore = useAuthStore();
    return { authStore : authStore }
  },
  name: 'DashBoard',
  data() {
    return {
      returnOrders: [],
      allSelected : false,
      modalError: {
          show: false,
          message: "unpopulated",
          error: "unpopulated",
          title: "unpopulated"
      },      
    }
  },
  computed:{
    // ...mapStores(useAuthStore)
    itemSelected(){
      return this.allSelected || this.selectedOrders.length > 0;
    },
    selectedOrders(){
      return this.returnOrders.filter(order => order.doc.isSelected === true)
    }
  }, 
  methods:{
    getAllOrders(){
      axios.get('/return_order/all').then(res => {
          // console.log(res);
          this.returnOrders = res.data.rows
      }).catch(err => {
          console.log(err);
      });
    },
    toggleAllOrderSelection(){
      this.returnOrders = this.returnOrders.map(order => {
          order.doc.isSelected = this.allSelected;
          return order;        
      })
    },
    deleteOrder(order){
      axios.post('/return_order/delete', order)
    // eslint-disable-next-line 
      .then(res => {
        // console.log(res);
      }).catch(err => {
        console.log(err);
      })
    },
  }, 
  mounted(){
    this.getAllOrders();
  }
}
</script>

