<template>
  <div class="card w-50 m-auto mt-3">
    <h4 class="mt-2">Create User</h4>
    <form class="m-2">
      <div class="input-group mb-3">
        <label class="input-group-text" for="email">E-Mail</label>
        <input type="text" id="email" v-model="email" class="form-control">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text"  for="name">Name</label>
        <input type="text" id="name" v-model="name" class="form-control">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text" for="password">Password</label>
        <input type="text" id="password" v-model="password" class="form-control">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text" for="role">Role</label>
        <select v-model="role" id="role" class="form-control">
          <option selected>Choose an option</option>
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text" for="hubId">Hub-Id</label>
        <select v-model="hubId" id="hubId" class="form-control">
          <option v-for="hub in hubs" :key="hub.id" selected>{{hub.id}}</option>
        </select>
      </div>
      <div v-if="errorMessage !== ''" class="text-danger w-50 text-center mx-auto mt-3">
        <h4>Error</h4>
        <b>{{errorMessage}}</b>
      </div>
      <div v-if="isUserCreated" class="text-success w-50 text-center mx-auto mt-3">
        <b>User created successfully</b>
      </div>
      <button type="button" class="btn btn-primary" @click="createUser">
        create
      </button>
    </form>
  </div>
</template>
<script>

import axios from "../interceptor/axiosInterceptor";

export default {
  data(){
    return {
      hubs: [],
      email: '',
      name: '',
      role: 'Choose an option',
      hubId: '',
      password: '',
      errorMessage: '',
      isUserCreated: false,
    }
  },
  methods: {
    createUser(){
      this.isUserCreated = false;
      if(!this.isSubmittable()) return;
      const req = {
        email: this.email,
        name: this.name,
        hubId: this.hubId,
        password: this.password,
        role: this.role
      }
      axios.post('/user/create', req)
        .then(res=> {
          // console.log(res);
          if(res.status == 201){
            this.isUserCreated = true;
          }
          this.email = null;
          this.name = null;
          this.role = null;
          this.password = null;
          this.hubId = null;

        })
        .catch(err =>{
          console.log(err);
          this.errorMessage = "An error occured while creating user doc"
        })
    },
    isSubmittable(){
      if(this.email === ''){
        this.errorMessage = "enter an email";
        return false
      } else if(this.name === '' || this.name == null){
        this.errorMessage = "enter the name of the users";
        return false
      } else if(this.password === '' || this.password == null){
        this.errorMessage = "enter a password";
        return false
      } else if(this.role === 'Choose an option' || this.role == null) {
        this.errorMessage = "choose a role";
        return false;
      } else if(this.hubId === 'Choose an option' || this.hubId == null) {
        this.errorMessage = "choose a HubId";
        return false;
      } else {
        this.errorMessage = "";
        return true;
      }
    }
  },
  created(){
    axios.get('/hub/all')
    .then(res=> {
      // console.log(res);
      this.hubs = res.data.rows;
    })
    .catch(err => {
      console.log(err);
    })
  }
}
</script>