<template>
  <div>
    <form class="col-md-3 mt-3 card bg-light mx-auto">
      <h1 class="h3 m-3 fw-normal">Please sign in</h1>
      <div class="form-floating m-2">
        <input v-model="email" type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating m-2">
        <input v-model="password" type="password" @keydown.enter="login()" class="form-control" id="floatingPassword" placeholder="Password">
        <label for="floatingPassword" >Password</label>
      </div>
        <div v-if="errorMessage !== '' " class="text-danger w-100 text-center mx-auto mt-3">
          <b>{{errorMessage}}</b>
        </div>
      <button type="button" @click="login()" class="btn btn-primary btn-lg w-auto m-2 py-2">Sign in</button>
    </form>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth.store"
// import { mapStores } from 'pinia'

// import axios from 'axios'
export default {
  setup(){
    const authStore = useAuthStore();
    return {authStore : authStore}
  },  
  data(){
    return {
      email: '',
      password: '',
      errorMessage: ''
    }
  },
  computed:{
    // ...mapStores(useAuthStore)
  },
  methods:{
    login(){
      if(this.email === ''){
        this.errorMessage = 'Email is required';
        return;
      }
      else if(this.password === ''){
        this.errorMessage = 'Password is required';
      }

      this.authStore.login(this.email, this.password)
      .catch(error=> {
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : 'An error occurred. Try again or contact support.';
      })
    }
  }
}
</script>