<template>
  <div>
    <div class="d-flex justify-content-between align-items-center" >
      <div class="d-flex align-items-center">
        <span class="ms-2">Package Type:</span>
        <select v-model="packageType" id="packageType" style="width: 150px" class="form-control mx-2">
          <option value="big">big</option>
          <option value="small">small</option>
          <option value="trash">trash</option>
        </select>
      </div>
      <h3 class="m-2" style="flex-grow: 1">Please check the orders:</h3>
      <div style="width: 270px"></div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"><input type="checkbox" v-model="allSelected" @change="toggleAllOrders()"></th>
        <th scope="col">Return Order Id</th>
        <th scope="col">EAN</th>
        <th scope="col">RMA</th>
        <th scope="col">Tracking-Id</th>
        <th scope="col">Customer Name</th>
        <th scope="col">quantity</th>
        <th scope="col">Condition</th>
        <th scope="col">comment</th>
        <th scope="col">actions</th>
<!--        <th scope="col">attachments</th>-->
      </tr>
      </thead>
      <tbody>
      <!--eslint-disable-next-line-->
      <tr v-for="(order, i) in returnOrders" :key="order._id" @click="clickedOrder(order)">
        <td class="align-middle"><input type="checkbox" titel="de/select this order" v-model="order.isSelected" @click="toggleOrder(order)"></td>
        <td class="align-middle"><p>{{order.id}}</p></td>
        <td class="align-middle"><div ><click-to-edit-component v-model="order.ean" :dataId="order._id" :dataPath="'/return_order/edit/'+order._rev+'/ean'"/></div></td>
        <td class="align-middle"><click-to-edit-component v-model="order.rma" :dataId="order._id" :dataPath="'/return_order/edit/'+order._rev+'/rma'"/></td>
        <td class="align-middle"><click-to-edit-component v-model="order.trackingId" :dataId="order._id" :dataPath="'/return_order/edit/'+order._rev+'/trackingId'"/></td>
        <td class="align-middle"><click-to-edit-component v-model="order.customerName" :dataId="order._id" :dataPath="'/return_order/edit/'+order._rev+'/customerName'"/></td>
        <td class="align-middle"><click-to-edit-component v-model="order.quantity" :dataId="order._id" :dataPath="'/return_order/edit/'+order._rev+'/quantity'"/></td>
        <td class="align-middle"><click-to-edit-component v-model="order.articleCondition" :dataId="order._id" :dataPath="'/return_order/edit/'+order._rev+'/articleCondition'"/></td>
        <td class="align-middle"><click-to-edit-component v-model="order.comment" :dataId="order._id" :dataPath="'/return_order/edit/'+order._rev+'/comment'"/></td>
        <td>
          <button class="btn btn-secondary mx-1" type="button" title="change Package Type" @click="showChangePackageTypeModal(order)">
            <i class="bi bi-box-seam"></i>
          </button>
          <button class="btn btn-secondary" type="button" title="show Images" @click="getAttachmentData(order)">
            <i class="bi bi-image"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- ImgeModal -->
    <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item" v-for="(image,imgId) in attachmentData" :key="imgId" :class="{ 'active': imgId === 0 }">
                  <img v-if="image.text" alt="image of attachment" :src="'data:image/jpeg;base64,'+image.text" class="w-100">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- change package Type Modal -->
    <div class="modal fade" id="changePackageTypeModal" tabindex="-1" aria-labelledby="changePackageTypeModal" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="changePackageTypeModal">Change Package Type of {{returnOrder?._id}}</h1>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center">
            <span class="ms-2">Package Type:</span>
              <select v-model="changedPackageType" id="packageType" style="width: 150px" class="form-control mx-2">
                <option value="big">big</option>
                <option value="small">small</option>
                <option value="trash">trash</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="changePackageType()">Save</button>
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-primary" @click="createShipment" :disabled="disableCreateShipment">Create Shipment</button>

    <!--success/error message -->
    <div v-if="resultMessage !== ''" class="text-success w-50 text-center mx-auto mt-3">
      <h4>Success</h4>
      <b>{{resultMessage}}</b>
    </div>
    <div v-if="errorMessage !== ''" class="text-danger w-50 text-center mx-auto mt-3">
      <h4>Error</h4>
      <b>{{errorMessage}}</b>
    </div>

  </div>
</template>
<style>
table .dropdown {
  position: relative;
}
</style>
<script>

import { useAuthStore } from '@/stores/auth.store'
/*import { mapStores } from 'pinia'*/

import axios from "@/interceptor/axiosInterceptor";
import ClickToEditComponent from "@/components/ClickToEditComponent.vue";
import moment from "moment";
// eslint-disable-next-line

export default {
  setup(){
    const authStore = useAuthStore();
    return { authStore : authStore }
  },
  components: { ClickToEditComponent },
  data(){
    return {
      returnOrders : [],
      resultMessage: '',
      errorMessage: '',
      returnOrder: null,
      allSelected: false,
      packageType: 'small',
      changedPackageType: '',
      attachmentData: [],
      modal: null,
    }
  },
  //need a watcher for packagetype
  watch:{
    packageType(){
      this.loadReturnOrdersByPackageType();
    }
  },
  computed:{
    selectedReturnOrders(){
      if (this.returnOrders.length == 0) return;
        return this.returnOrders.filter(order => order.isSelected === true);
    },
    disableCreateShipment(){
      return (this.returnOrders.length <= 0 || this.selectedReturnOrders.length <= 0)
    },
  },
  methods:{
    loadReturnOrdersByPackageType(){
      if(this.packageType !== ''){
        axios.get('/return_order/'+ this.packageType).then(res => {
          console.log(res);
          this.returnOrders = res.data.rows?.map(order=> {
            order = order.doc;
            order.isSelected = false;
            return order;
          })
        }).catch(err => {
          console.log(err);
        })
      }
    },
    toggleOrder(order){
      order.isSelected = !order.isSelected
    },
    toggleAllOrders(){
      if (this.returnOrders.length == 0) return;
      this.returnOrders.forEach(order => {
        order.isSelected = this.allSelected
      })
    },
    getImgUrlFromBlob(blob){
      return URL.createObjectURL(blob)
    },
    getAttachmentData(order){
      axios.post("/return_order/attachments", order).then(res => {
        console.log(res);
        this.attachmentData = res.data;
        this.attachmentData.map(att=> {
          const uint8Array = new Uint8Array(att.data.data);
          att.blob = new Blob([uint8Array],{type: 'image/jpeg'});
          att.blob.text().then(res => {
            att.text = res;
          })
          return att;
        })
        // eslint-disable-next-line
        this.modal = new bootstrap.Modal(document.getElementById('imageModal'));
        this.modal.show();
      }).catch(err => {
        console.log(err);
      })
    },
    getPackageTypeAbbreviation(){
      if(this.packageType === 'big') return 'B';
      if(this.packageType === 'small') return 'SM';
      if(this.packageType === 'trash') return 'T';
    },
    createShipment(){
      // eslint-disable-next-line no-unused-vars
      const pluckedReturnOrders = this.selectedReturnOrders.map(({attachmentData, ...rest }) => rest);
      const req = {
        returnOrders: pluckedReturnOrders,
        hubId: this.authStore.user?.hubId,
        orderCount: pluckedReturnOrders.length,
        tspId: this.authStore.user?.hubId + '-S'+ moment().format('DDMMYYYY')+ this.getPackageTypeAbbreviation(),
        createdAt : moment().format('YYYY-MM-DD HH:mm:ss'),
      }
      axios.post('/shipment/create', req)
    // eslint-disable-next-line 
      .then(res => {
        // console.log(res);
        this.allSelected = false;
        this.resultMessage = 'Shipment created successfully'
        this.returnOrders = this.returnOrders.filter(order => !this.selectedReturnOrders.includes(order))
      }).catch(err => {
        console.log(err);
        this.errorMessage = 'An Error occurred: ' + err
      })
    },

    clickedOrder(order){
      this.returnOrder = order;
    },
    showChangePackageTypeModal(order){
      this.returnOrder = order;
      // eslint-disable-next-line no-undef
      this.modal = new bootstrap.Modal(document.getElementById('changePackageTypeModal'));
      this.modal.show();
    },
    changePackageType(){
      const req = {
        id: this.returnOrder._id,
        value: this.changedPackageType
      }
      axios.put('/return_order/edit/'+this.returnOrder._rev+'/packageType', req).then(res => {
        console.log(res);
        this.modal.hide();
        this.loadReturnOrdersByPackageType();
      }).catch(err => {
        console.log(err);
      })
    }
  },
  created() {
    //getting returnOrders where shipmentId = null
    axios.get('/return_order/'+ this.packageType).then(res => {
      console.log(res);
      this.returnOrders = res.data.rows?.map(order=> {
        order = order.doc;
        order.isSelected = false;
        return order;
      })
    }).catch(err => {
      console.log(err);
    });
  }
}
</script>