<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>Camera</h2>
                <div v-show="isCamOn">
                    <vue-camera
                        ref="webcam"
                        @started="onStarted"                  
                        @stopped="onStopped"
                        @error="onError"
                        :autoplay="false"
                        @cameraChange="onCameraChange"
                    />
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <select v-model="camera">
                            <option>-- choose a camera --</option>
                            <option
                                v-for="device in devices"
                                :key="device.deviceId"
                                :value="device.deviceId"
                            >{{ device.label }}</option>
                        </select>
                    </div>
                    <div class="col-md-12 input-group justify-content-center mt-2">
                        <button v-if="!isCamOn" type="button" class="btn btn-success" @click="onStart()">turn camera on</button>
                        <button v-else type="button" class="btn btn-danger" @click="onStop()">turn off</button>
                        <button type="button" :disabled="!isCamOn" class="btn btn-primary" @click="onCapture">take picture</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h2>last picture</h2>
                <img v-if="img" :src="img" class="img-fluid w-100" @click="showImage(image)"/>
                <div v-for="(image,imgId) in this.images" :key="imgId" class="position-relative w-50" style="display: inline-block">
                        <img :src="image.imgUrl" class="w-100 my-0" @click="showImage(image)"/>
                        <button type="button" class="deleteButton btn btn-sm btn-danger" @click="onDelete(image)">
                            <i class="bi bi-x-square"></i>
                        </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCamera from "simple-vue-camera";


export default {
    components: { 
        VueCamera,
    },
    prop: ['modelValue'],
	data() {
        return {
            img: null,
            images: [],
            camera: null,
            deviceId: null,
            devices: [],
            picReference: '',
            isCamOn: false,
            imagesData: [],
        };
    },
    computed: {
        device: function() {
            if (this.devices == null) return null
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        rawDataArray() {
            return this.images.map(image => image.rawData);
        }        
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
            if(this.isCamOn){
                this.$refs.webcam.changeCamera(id)
            }
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices; // eslint-disable-line no-unused-vars
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
    },
    methods: {
        async onCapture() {
            const imgData = await this.$refs.webcam.snapshot(undefined, "image/jpeg", 1);
            this.images.push({rawData: imgData, imgUrl: URL.createObjectURL(imgData)})
            this.$emit('update:modelValue', this.rawDataArray);
            // console.log('emitted');
        },
        onStopped() {
            this.isCamOn = false
        },
        onStarted() {
            this.isCamOn = true
        },        
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            // console.log(cameras);
            this.devices = cameras;
            // console.log("On Cameras Event", cameras);
        },
        getDevices() {
            this.$refs.webcam.devices(['videoinput']).then(
                res => {
                    this.devices = res;
                }
            );
        },        
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            
            // console.log("On Camera Change Event", deviceId);
        },
        showImage(image){
            if(this.img == image) {
                this.image = null;
                return;
            }
            this.img = image;
        },
        onDelete(image){

            this.images = this.images.filter(img => img.imgUrl !== image.imgUrl)
            this.$emit('update:modelValue', this.rawDataArray);
            // console.log('emitted');
        },
        resetImages(){
            this.images = [];
            this.$emit('update:modelValue', this.rawDataArray);
        }
    },
    mounted(){
        this.getDevices()

    }

}
</script>

<style scoped>
  .latestImages{
    max-height: 500px !important;
  }
  .deleteButton{
    position: absolute;
    bottom: 0px; 
    right: 0px;    
  }
</style>