/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from "@/stores/auth.store";
import  DashBoard  from '../views/Dashboard.vue'
import  ShipmentsView  from '../views/ShipmentsView.vue'
import  LoginView from '../views/LoginView.vue'
import  CreateUserView from '../views/CreateUserView.vue'
import  CreateShipmentView from '../views/CreateShipmentView.vue'
const routes = [
    {
        path: '/',
        name: 'dash-board',
        component: DashBoard,
        meta:{
            title : 'Home'
        }
    },
    {
        path: '/shipments',
        name: 'shipments-view',
        component: ShipmentsView,
        meta:{
            title : 'Shipments'
        }
    },
    {
        path: '/login',
        name: 'login-view',
        component: LoginView,
        meta:{
            title : 'Login'
        }
    },
    {
        path: '/create_user',
        name: 'create-user-view',
        component: CreateUserView,
        meta:{
            title : 'Create User'
        }
    },
    {
        path: '/shipment/create',
        name: 'create-shipment-view',
        component: CreateShipmentView,
        meta:{
            title : 'Create Shipment'
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
 
router.beforeEach(async (to) => {
    const publicPages =  ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();
    document.title = to.meta?.title ?? 'Return Collector'

    if((authRequired && !auth.token)){
        auth.returnUrl = to.fullPath;
        // console.log(to.fullPath);

        return '/login';
    }
});

export default router;